import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-delete-term',
  templateUrl: './delete-term.component.html',
  styleUrls: ['./delete-term.component.css']
})
export class DeleteTermComponent implements OnInit {
  accessToken;
  constructor() { }

  ngOnInit(): void {
    this.accessToken = localStorage.getItem("accessToken");
  }
}
