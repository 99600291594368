<app-external-header *ngIf="!accessToken"></app-external-header>
<app-header-jobseeker *ngIf="accessToken"></app-header-jobseeker>
<div class="wrapper">
    <section class="terms_conditions">
      <div class="container">
        <div class="termsWrap">
        
            
          <h2>Close Your Account with Us</h2>
        
         
                <h2 class="m-t-10">1. Access the Menu:</h2>
               
                    <ul>
                        <li>
                            Tap on your profile icon or menu icon to open the side menu.
                        </li>
                        <li>Select <b>Account Settings.</b></li>
                    </ul>
                    <h2 class="m-t-10">2. Navigate to Delete Account:</h2>
                    <ul>
                        <li>
                            In the Account Settings screen, scroll down and tap on <b>Delete Account</b>.
                        </li>
                    </ul>
                    <h2 class="m-t-10">3. Confirm Password:</h2>
                    <ul>
                        <li>
                            Enter your account password in both the <b>Password</b> and <b>Confirm Password</b> fields.
                        </li>
                        <li>
                            Ensure that your password meets the specified criteria:
                            <ul class="square">
                                <li>Minimum of 8 characters.</li>
                                <li>At least one uppercase letter.</li>
                                <li>At least one number.</li>
                                <li>At least one special character.</li>
                            </ul>

                        </li>
                    </ul>
                    <h2 class="m-t-10">4. Final Confirmation:</h2>
                    <ul>
                        <li>
                            After entering your password, tap on <b>Delete Account</b>.
                        </li>
                        <li>A confirmation popup will appear asking if you’re sure you want to delete your account.</li>
                        <li>Tap <b>Delete</b> to confirm and permanently delete your account.</li>
                    </ul>
                    <p><b>Note:</b> Once you delete your account, all your data will be removed and cannot be recovered.</p>
          </div>
          </div>
          </section>
          </div>