import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { AdminService } from "../../services/admin.service";
import { apiUrl } from "../../global/global";
import { ImageUrls } from "src/app/services/s3buket";
import swal from "sweetalert2";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";

@Component({
  selector: "app-external-header",
  templateUrl: "./external-header.component.html",
  styleUrls: ["./external-header.component.css"]
})
export class ExternalHeaderComponent implements OnInit {
  showHead: boolean = true;
  showLogout: boolean = false;
  isPasswordSet: any;
  deviceToken;
  userData: any;
  userId: any;
  dummyImage: any = "../../../assets/images/profile-img.jpg";
  globalUrl = ImageUrls.BaseUrl + ImageUrls.directories.users
  unreadCount: any;
  closeSearch = false;
  allJobs: any = [];
  search: any;
  searchObserver = new Subject<string>();
  constructor(private router: Router, public route: ActivatedRoute, private api: AdminService) {
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        // if (val.url.endsWith("/home")){
        if (val.urlAfterRedirects.endsWith("/home") || val.urlAfterRedirects.endsWith("/employers") || val.urlAfterRedirects.endsWith("/job-seekers") || val.urlAfterRedirects.endsWith("/about") || val.urlAfterRedirects.endsWith("/faq") || val.urlAfterRedirects.endsWith("/terms") || val.urlAfterRedirects.endsWith("/privacy") || val.urlAfterRedirects.endsWith("/cookies")) {
          this.showHead = true;
          this.showLogout = false;
        } else if (this.router.url.endsWith("/upload-document") || this.router.url.endsWith("/complete-profile")) {
          this.showLogout = true;
        }
        else {
          this.showLogout = false;
          this.showHead = false;
        }
      }
    })
    this.api.getSearch().subscribe(search => {
      this.search = search
      this.closeSearch = false;
    });
  }

  ngOnInit(): void {
    this.isPasswordSet = localStorage.getItem("isPasswordSet")
    this.deviceToken = localStorage.getItem("deviceToken")
    this.searchObserver.pipe(
      debounceTime(400),
      distinctUntilChanged())
      .subscribe(value => {
        if (value == "") {
          this.allJobs = [];
        } else {
          this.login();
        }
      });
  }
  redirectTo(event, number) {
    this.showHead = false;
    this.showLogout = false;
    let user = localStorage.getItem("login_type");
    if (number == 0) {
      if (this.userId != null && user != "userNotApproved") {
        this.router.navigate(["dashboard-job"]);
      } else if (this.userId != null && user == "userNotApproved") {
        this.router.navigate(["edit-profile"]);
      } else {
        this.router.navigate(["login"]);
      }
    }
    else {
      if (this.userId != null && user != "userNotApproved") {
        this.router.navigate(["dashboard"]);
      } else if (this.userId != null && user == "userNotApproved") {
        this.router.navigate(["edit-profile"]);
      } else {
        this.router.navigate(["employer"]);
      }
    }
  }
  logout() {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
      swal.fire({
        html: '<p class="gilroy-bold">Are you sure you want to Logout?</p>',
        showCloseButton: false,
        showCancelButton: true,
        focusConfirm: false,
        customClass:{ popup:'post-popup logout-pop'},
        confirmButtonAriaLabel: 'Delete post',
        cancelButtonAriaLabel: 'Cancel',
        confirmButtonColor: '#5A50CC',
        cancelButtonColor: '#ffffff',
        cancelButtonText: 'No',
        confirmButtonText: 'Yes',
        reverseButtons: true,
        width: 500,

      }).then((result) => {
        const data = {
          "deviceToken": this.deviceToken
        }
        this.api.postData(apiUrl._logout, data).subscribe(
          res => {
            if (result.value) {
              this.clearLocalStorage();
            }
          });
      })
    } else {
      this.clearLocalStorage();
    }
  }
  clearLocalStorage() {
    window.localStorage.clear();
    localStorage.clear()
    localStorage.removeItem("accessToken");
    localStorage.removeItem("deviceToken");
    localStorage.removeItem("UserData");
    localStorage.removeItem("isPasswordSet");
    localStorage.removeItem("login_type");
    localStorage.removeItem("userType");
    localStorage.removeItem("userDetail");
    localStorage.removeItem("s3Folders");
    this.router.navigate(["/home"]);
  }

  searchFun() {
    if (this.search != '') {
      this.closeSearch = true;
      this.api.setSearch(this.search);
      this.login();
    } else {
      this.closeSearch = false;
    }
  }
  onTextChange(value: any) {
    this.search = value
    if (this.search != '' && this.search.length > 2) {
      this.api.setSearch(this.search);
      this.closeSearch = true;
      // this.getJobs();
      this.searchObserver.next(this.search)
    } else {
      this.closeSearch = false;
      this.search = '';
    }
  }
  refreshPage() {
    this.closeSearch = false;
    this.search = '';
    this.api.setSearch(this.search);
  }
  login() {
    swal.fire({
      html: '<p class="gilroy-bold">For Search your dream job you need to login</p>',
      showCloseButton: false,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonColor: '#FE831D',
      cancelButtonColor: '#ffffff',
      confirmButtonText: 'Login',
      reverseButtons: false,
      width: 500,

    }).then((result) => {
      if (result.isConfirmed) {
        this.router.navigate(["/login"]);
      }
    })

  }

  menu_icon_variable: boolean = false;
  menuVariable: boolean = false;

  openMenu() {
    this.menuVariable = !this.menuVariable;
    this.menu_icon_variable = !this.menu_icon_variable;
  }
}
