// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
export const environment = {
  //  apiUrl: "https://lc0ekgayo5.execute-api.us-east-1.amazonaws.com/dev/",
  apiUrl: "https://w3jke9psob.execute-api.us-east-1.amazonaws.com/stage/",
  // apiUrl: "https://api.honorvets.com/",
  production: false, 
}; 
/*6.0",
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.